<template>
  <footer>
    <div class="footer-wrapper">
      <div class="footer-top">
        <div class="w-layout-grid grid-footer-v1">
          <div id="footer-logo-id-wrapper">
            <a href="/" class="footer-logo-wrapper">
              <img src="/img/logo.png" alt="" class="footer-logo" />
            </a>
            <div class="social-media-grid">
              <a href="https://twitter.com/PearlDiver_O" target="_blank" class="social-media-icon">
                <img src="/img/icons/xSocial.svg" height="35" alt="x" />
              </a>
              <a href="https://discord.gg/mgMQdnevtR" target="_blank" class="social-media-icon">
                <div class="discord social-common"></div>
              </a>
              <a href="https://t.me/+5kNm7PE4bckzNzZi" target="_blank" class="social-media-icon">
                <div class="telegram social-common"></div>
              </a>
            </div>
          </div>
          <div>
            <div class="footer-title">{{ $t('footerGameSections') }}</div>
            <div class="w-layout-grid grid-2-columns footer-lists-wrapper">
              <ul role="list" class="footer-list-wrapper">
                <li v-if="$device.isDesktop" class="footer-list-item">
                  <NuxtLink to="/" class="footer-link"> {{ $t('homeTitle') }} </NuxtLink>
                </li>
                <li v-else class="footer-list-item">
                  <a :href="landingUrl" class="footer-link">{{ $t('menuHome') }}</a>
                </li>

                <li v-if="$device.isMobile" class="footer-list-item">
                  <NuxtLink to="/story" class="footer-link"> {{ $t('menuStory') }} </NuxtLink>
                </li>
                <li class="footer-list-item">
                  <NuxtLink to="/marketplace" class="footer-link"> {{ $t('menuMarketplace') }} </NuxtLink>
                </li>
                <li class="footer-list-item">
                  <NuxtLink to="/dashboard" class="footer-link"> {{ $t('menuDashboard') }} </NuxtLink>
                </li>
                <li v-if="$device.isDesktop" class="footer-list-item">
                  <NuxtLink to="/game" class="footer-link"> {{ $t('gameTitle') }} </NuxtLink>
                </li>
              </ul>
              <ul role="list" class="footer-list-wrapper">
                <li class="footer-list-item">
                  <a
                    href="https://pearl-diver.gitbook.io/pearl-diver-whitepaper/"
                    target="_blank"
                    class="footer-link"
                    >{{ $t('footerWhitepaper') }}</a
                  >
                </li>
                <li class="footer-list-item">
                  <a
                    href="https://pearl-diver.gitbook.io/pearl-diver-whitepaper/tokenomics/general-token-information"
                    target="_blank"
                    class="footer-link"
                    >{{ $t('footerTokenomics') }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div id="w-node-_313b9b56-60d7-5321-03e8-41f2f58714ff-1ee5f160">
            <div class="footer-title">{{ $t('footerLegal') }}</div>
            <ul role="list" class="footer-list-wrapper">
              <li class="footer-list-item">
                <a :href="'/terms_and_conditions.pdf'" target="_blank" class="footer-link">{{
                  $t('footerTermsConditions')
                }}</a>
              </li>
              <li class="footer-list-item">
                <a :href="'/privacy_policy.pdf'" target="_blank" class="footer-link">{{ $t('footerPrivacyPolicy') }}</a>
              </li>
              <li class="footer-list-item">
                <a href="mailto:support@pearldivergame.com" class="footer-link">{{ $t('footerSupport') }} </a>
              </li>
            </ul>
          </div>

          <div v-if="!$device.isMobile" id="captain-wrapper" class="footer-last">
            <div class="footer-title">{{ $t('footerSubscribeToNewsletter') }}</div>
            <div
              data-w-id="313b9b56-60d7-5321-03e8-41f2f587150f"
              class="subscribe-wrapper"
              @click="isShowSubscriptionForm = true"
            >
              <img class="subscribe-image" src="/img/pirate_subscribe.png" loading="eager" alt="" />
              <div class="subscribe-image-overlay radial">
                <img
                  src="https://uploads-ssl.webflow.com/647f311bff65ecf85cb409e6/647f311cff65ecf85cb40b6a_apply-icon-generativeai-x-webflow-template.svg"
                  loading="eager"
                  alt="Apply For This Position Icon - GenerativeAI X Webflow Template"
                  class="instagram-feed-icon-inside"
                />
              </div>
            </div>
          </div>
          <div v-else class="footer-title mobile">{{ $t('footerSubscribeToNewsletter') }}</div>
        </div>
      </div>

      <el-dialog
        v-model="isShowSubscriptionForm"
        :title="$t('footerSubscriptionForm')"
        :fullscreen="$device.isMobile"
        class="buy-popup"
        :class="{ 'ru-locale': $i18n.locale === 'ru' }"
        :append-to-body="true"
      >
        <el-form ref="subscriptionFormRef" :model="subscriptionForm" :rules="rules" @submit.prevent>
          <el-form-item label="" prop="email">
            <el-input v-model="subscriptionForm.email" name="email" :placeholder="$t('footerEmailAddress')" />
          </el-form-item>
          <div class="buttons-wrapper">
            <button :disabled="!isEmailValid" class="btn-primary" size="large" @click="submitSubscribeHandler">
              <span>{{ $t('footerSubscribe') }}</span>
            </button>
          </div>
        </el-form>
      </el-dialog>

      <div v-if="$device.isMobile" class="subscribe-form-mobile">
        <el-form ref="subscriptionFormRef" :model="subscriptionForm" :rules="rules" @submit.prevent>
          <el-form-item label="" prop="email">
            <el-input v-model="subscriptionForm.email" name="email" :placeholder="$t('footerEmailAddress')" />
          </el-form-item>
          <div class="buttons-wrapper">
            <button :disabled="!isEmailValid" class="btn-primary" size="large" @click="submitSubscribeHandler">
              <span>{{ $t('footerSubscribe') }}</span>
            </button>
          </div>
        </el-form>
      </div>

      <div class="footer-bottom">
        <p class="mg-bottom-0">©{{ new Date().getFullYear() }} – {{ $t('footerPerlDiverIsland') }}.</p>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import type { FormInstance, FormRules } from 'element-plus';
import { ElNotification } from 'element-plus';
import { reactive, ref } from 'vue';
import PearlApiService from '~/composables/PearlApiService';
import { ERRORS_CONFIG } from '~/utils/constants';
import type { TSubscribeEmailModel } from '~/types/apiService';
import useEnvs from '~/composables/useEnvs';
import { useI18n } from '#imports';

const { t } = useI18n();

const { landingUrl, apiUrl } = useEnvs();
const isShowSubscriptionForm = ref(false);
const subscriptionFormRef = ref<FormInstance>();
const isEmailValid = ref(false);
const subscriptionForm = reactive({
  email: ''
});

const validateEmail = (rule: any, value: string, callback: any) => {
  const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  if (!value.match(mailformat)) {
    callback(new Error(t('correctEmailError')));
    isEmailValid.value = false;
  } else {
    isEmailValid.value = true;
  }
};

const rules = reactive<FormRules>({
  email: [{ validator: validateEmail, trigger: 'change' }]
});

const submitSubscribeHandler = async () => {
  const subscribePayload: TSubscribeEmailModel = {
    contact: {
      channels: [{ type: 'email', value: subscriptionForm.email }]
    }
  };
  const subscribeRes = await PearlApiService.subscribeEmail(apiUrl, subscribePayload);
  if (subscribeRes?.data?.id) {
    ElNotification.success({
      title: t('successSubscriptionDoneMsg'),
      message: t('successSubscriptionMsg')
    });
    isShowSubscriptionForm.value = false;
    isEmailValid.value = false;
    subscriptionForm.email = '';

    subscriptionFormRef.value?.resetFields();
  } else if (subscribeRes?.status === 429) {
    ElNotification.error(ERRORS_CONFIG.apiErrors.emailSubscription.frequentRequest);
  } else if (subscribeRes?.status === 409) {
    ElNotification.error(t('emailAlreadySubscribed'));
  } else {
    ElNotification.error({
      title: '',
      message: subscribeRes?.data?.message
    });
  }
};
</script>

<style scoped lang="scss">
footer {
  background: var(--blue-1);
  box-sizing: border-box;
  border-top: 1px solid var(--blue-2);
}

.buttons-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.footer-wrapper {
  max-width: 1340px;
  padding-left: 24px;
  padding-right: 24px;
  margin: auto;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  display: inline-flex;
}

.icon-pencil {
  background: url('~/public/img/icons/pencil.png') center center no-repeat;
  background-size: contain;
}

.icon-base {
  background: url('~/public/img/icons/database.png') center center no-repeat;
  background-size: contain;
}

.icon-paper {
  background: url('~/public/img/icons/paper.png') center center no-repeat;
  background-size: contain;
}

.footer-bottom {
  text-align: center;
  border-top: 1px solid var(--blue-2);
  padding-top: 40px;
  padding-bottom: 40px;
}

.footer-top {
  padding-top: 115px;
  padding-bottom: 115px;
}

.footer-logo-wrapper {
  max-width: 279px;
  margin-bottom: 12px;
  max-width: 100%;
  display: inline-block;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.grid-2-columns {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.grid-2-columns.footer-lists-wrapper {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  grid-template-columns: auto auto;
  justify-content: stretch;
  align-items: start;
}

.grid-footer-v1 {
  grid-column-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: minmax(auto, 300px) minmax(auto, 327px) minmax(119px, 171px) minmax(200px, 350px);
  justify-content: space-between;
  font-family: Grenze, sans-serif;
}

@media screen and (max-width: 991px) {
  .footer-top {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .footer-logo-wrapper {
    max-width: 233px;
  }

  #footer-logo-id-wrapper {
    grid-area: span 1 / span 2 / span 1 / span 2;
    grid-column-start: span 2;
    grid-column-end: span 2;
    justify-self: center;
  }

  .footer-bottom {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  #captain-wrapper {
    grid-area: 3 / 1 / 4 / 3;
  }
}

@media screen and (max-width: 767px) {
  .footer-logo-wrapper {
    max-width: 194px;
  }

  .footer-top {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .grid-2-columns {
    grid-template-columns: 1fr;
  }

  #footer-logo-id-wrapper {
    grid-column: span 2 / span 2;
  }

  .footer-bottom {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  #captain-wrapper {
    grid-area: span 1 / span 2 / span 1 / span 2;
    align-self: auto;
  }
}

@media screen and (max-width: 479px) {
  .footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
  }

  #footer-logo-id-wrapper {
    grid-column: span 1 / span 1;
  }

  .mg-bottom-0 {
    margin-bottom: 5px;
  }

  #captain-wrapper {
    grid-column: span 1 / span 1;
  }
}

@media screen and (max-width: 479px) {
  .footer-top {
    padding-top: 40px;
    padding-bottom: 20px;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      right: -25%;
      height: 200px;
      width: 200px;
      border-radius: 50%;
      background: #51fbff;
      filter: blur(200px);
      z-index: 0;
    }
  }
}

@media screen and (max-width: 991px) {
  .grid-footer-v1 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .grid-footer-v1 {
    grid-column-gap: 20px;
    grid-row-gap: 60px;
    grid-template-columns: 1fr minmax(auto, 0.75fr);
  }
}

@media screen and (max-width: 479px) {
  .grid-footer-v1 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }
}

.social-media-grid {
  margin-top: 32px;
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.social-media-icon {
  text-decoration: none;
}

.discord,
.telegram {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 30px;
  height: 30px;
  transition:
    transform 0.3s ease,
    background 0.3s ease;
}

.discord:hover,
.telegram:hover {
  transform: scale(1.1);
}

.discord {
  background-image: url('/public/img/icons/discord.svg');
}

.discord:hover {
  background-image: url('/public/img/icons/discord_hover.svg');
}

.telegram {
  background-image: url('/public/img/icons/telegram.svg');
}

.telegram:hover {
  background-image: url('/public/img/icons/telegram_hover.svg');
}

.footer-title {
  color: #fff;
  font-size: 26px;
  font-weight: 900;
  line-height: 1.417em;
  margin-bottom: 20px;

  @media screen and (max-width: 479px) {
    font-size: 24px;
    font-weight: 800;
    text-align: center;
  }
}

.footer-title.mobile {
  padding-top: 44px;
}

.footer-list-wrapper {
  grid-row-gap: 18px;
  grid-template-rows: auto auto;
  grid-template-columns: auto;
  grid-auto-columns: auto;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  display: grid;
  margin-top: 0px;
  font-size: 18px;
  font-weight: 500;

  @media screen and (max-width: 479px) {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
  }
}

.subscribe-form-mobile {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -120%;
    right: -25%;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    background: #51fbff;
    filter: blur(200px);
    z-index: 0;
  }

  .el-input {
    font-family: 'Grenze', sans-serif;
    height: 56px;
    background-color: #05272c;
    font-size: 20px;
    font-weight: 500;
    border-radius: 12px;
  }

  .btn-primary {
    font-size: 20px;
    font-weight: 700;
    font-family: 'Grenze', sans-serif;
  }

  .buttons-wrapper {
    margin-bottom: 10px;
  }
}

.footer-list-item {
  margin-bottom: 0;
  padding-left: 0;
  line-height: 1.111em;
}

.footer-link {
  color: rgba(255, 255, 255, 0.4);
  text-decoration: none;
  transition:
    transform 0.3s,
    color 0.3s;
  display: inline-block;
}

.footer-link:hover {
  color: #fff;
  transform: translate(6px);
}

.subscribe-image {
  width: 100%;
  object-fit: cover;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
  transform-style: preserve-3d;
  transition: 200ms ease all;
}

.footer-logo {
  width: 100%;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.5;
  }
}

@media screen and (max-width: 991px) {
  .footer-last {
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    display: flex;
  }
}

.instagram-feed-icon-inside {
  opacity: 0;
  transform: translate3d(0px, 0px, 0px) scale3d(1.2, 1.2, 1.01) rotateX(0deg) rotateY(0deg) rotateZ(-8deg) skew(0deg);
  transform-style: preserve-3d;
  max-width: 40px;
  min-width: 40px;
  transition: 200ms ease all;
}

.subscribe-image-overlay.radial {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  background-image: radial-gradient(circle farthest-side, rgba(2, 22, 31, 0.8), rgba(0, 0, 0, 0));
}

.subscribe-image-overlay {
  z-index: 1;
  transform-origin: 0%;
  background-color: rgba(0, 0, 0, 0.65);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  opacity: 0;
  transition: 200ms ease all;

  &:hover {
    opacity: 1;
    .instagram-feed-icon-inside {
      opacity: 1;
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-8deg) skew(0deg);
    }
  }
}

.subscribe-wrapper {
  z-index: 1;
  border-radius: 6px;
  display: flex;
  position: relative;
  transform: translate(0);

  &:hover {
    .subscribe-image {
      transform: translate3d(0px, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
    }
  }

  @media screen and (max-width: 479px) {
    width: 80%;
  }

  @media screen and (max-width: 991px) {
    width: 40%;
  }
}
</style>

<style>
.subscribe-form-mobile {
  .el-input__wrapper.is-focus {
    box-shadow: 0 0 0 1px #1e353e inset;
  }

  .el-input__wrapper {
    box-shadow: 0 0 0 1px rgba(30, 53, 62, 0.55) inset;
    position: relative;
    z-index: 1;
    border-radius: 12px;
  }

  .el-input__inner {
    color: white;
    text-align: center;

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
</style>
