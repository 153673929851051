<template>
  <div>
    <AppHeader />

    <div
      v-if="$device.isMobile && ($route.path === path.public.marketplace || $route.path === path.public.dashboard)"
      :class="{ 'video-wrapper': $device.isDesktop }"
    >
      <VideoOverlay />
    </div>
    <div class="page">
      <div
        id="main-body-container"
        ref="parallaxContainerRef"
        :class="{
          'main-body-container':
            $route.path !== path.public.home && $route.path !== path.public.game && $route.path !== path.public.story,
          'parallax-container':
            store.isNewYearTheme && $route.path !== path.public.home && $route.path !== path.public.game
        }"
      >
        <div
          v-if="store.isNewYearTheme"
          :class="{
            'new-year': store.isNewYearTheme && $route.path !== path.public.home && $route.path !== path.public.game
          }"
          :style="parallaxLayer"
        />
        <NuxtPage />
      </div>

      <div class="mobile-footer">
        <AppFooter />
      </div>
      <div class="footer">
        <AppFooter />
      </div>
    </div>

    <el-dialog
      v-model="showPopupValue"
      :class="{ 'ru-locale': $i18n.locale === 'ru' }"
      :title="$t('notificationError')"
      :append-to-body="true"
      @closed="close"
    >
      <div class="error-wrapper">
        {{ error }}
      </div>
      <div class="btns">
        <button class="btn-primary" @click="close">{{ $t('coreClose') }}</button>
      </div>
    </el-dialog>

    <TransactionNotifications :key="notificationUpdateVersion" />
  </div>
</template>

<script lang="ts">
import { type CSSProperties, defineComponent } from 'vue';
import { useMainStore } from '~/stores/mainStore';
import { mapState, mapActions } from 'pinia';
import { updateProcessingTransactions } from '~/utils';
import useEnvs from '~/composables/useEnvs';
import { path } from '~/utils/constants';
import { useDevice } from '#imports';
import { useParallax } from '@vueuse/core';

export default defineComponent({
  async setup() {
    const refMainContainer = ref<HTMLDivElement>();
    const parallaxContainerRef = ref<HTMLDivElement>();

    const store = useMainStore();
    const route = useRoute();
    const { blockchain, apiUrl } = useEnvs();
    const {
      public: { env }
    } = useRuntimeConfig();
    const { isMobile } = useDevice();
    const { tilt, roll } = useParallax(parallaxContainerRef);

    return {
      blockchain,
      apiUrl,
      env,
      route,
      path,
      isMobile,
      parallaxContainerRef,
      refMainContainer,
      store,
      tilt,
      roll
    };
  },
  data(): {
    loading: boolean;
    animation: string;
  } {
    return {
      loading: false,
      animation: ''
    };
  },
  computed: {
    ...mapState(useMainStore, ['error', 'notificationUpdateVersion', 'isNewYearTheme']),
    showPopupValue(): boolean {
      return !!this.error;
    },

    parallaxLayer(): CSSProperties {
      if (!this.isNewYearTheme) {
        return {};
      }

      return {
        height: this.route.path === path.withAuth.referral ? 'calc(105% + 30px)' : 'calc(100% + 80px)',
        transform: `translateX(${this.tilt * 20}px) translateY(${this.roll * 20}px)`
      };
    }
  },
  async mounted() {
    if (document) {
      document.getElementsByTagName('html')[0].classList.add('dark');
    }

    updateProcessingTransactions(); // show all processing transaction
    this.updateVersion();

    const currentMonth = new Date().getMonth();
    const isWinterMonth = currentMonth === 11 || currentMonth === 0;
    this.setIsNewYearTheme(isWinterMonth);
  },
  methods: {
    ...mapActions(useMainStore, [
      'setError',
      'updateVersion',
      'setUserData',
      'setUserSubscribersData',
      'setIsNewYearTheme'
    ]),
    close() {
      this.setError('');
    },
    runAnimation(name: string) {
      this.animation = name;
    },
    resetAnimation() {
      this.animation = '';
    }
  }
});
</script>

<style scoped lang="scss">
#content {
  position: absolute;
  right: 0;
  left: 0;
}

.video-wrapper {
  transform: translateY(150px);

  @media screen and (max-width: 479px) {
    transform: translateY(80px);
  }
}

.mobile-footer {
  display: none;
}

@media screen and (max-width: 1200px) {
  .mobile-footer {
    display: block;
  }

  .footer {
    display: none;
  }
}

.page {
  padding-top: 150px;
  height: auto;
  background-color: var(--blue-1);
  background-image: linear-gradient(rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.1));
  overflow: hidden;

  @media screen and (max-width: 767px) {
    padding-top: 0;
  }
}

.parallax-container {
  position: relative;

  .main-body-container {
    position: relative;
    z-index: 2;
  }

  .new-year {
    z-index: 0;
    position: absolute;
    top: -55px;
    left: -25%;
    width: 150%;
    background-image: url('~/public/img/newYear/snowflakes.png');
    background-repeat: repeat;
    transition: 0.3s ease-out all;

    @media screen and (max-width: 760px) {
      top: -50px;
    }

    @media screen and (min-width: 1920px) {
      left: -50%;
      width: 200%;
    }
  }
}
.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 1px);
}

.error-wrapper {
  margin-bottom: 12px;
  text-align: center;
}

.map-container {
  position: relative;
}
</style>

<style lang="scss">
.el-dialog.login-popup {
  width: 280px;
}

.el-dialog.video-popup {
  width: 100%;
  max-width: 720px;

  .el-dialog__body {
    max-width: 700px;
  }
}

.el-dialog.full-body {
  width: 100%;
  max-width: 720px;

  .el-dialog__body {
    max-width: 100%;
  }
}
.el-dialog.buy-popup {
  max-width: 550px;
  min-width: 450px;

  .show-close {
    padding-right: 0;
  }

  @media screen and (max-width: 479px) {
    min-width: unset;
  }
}

.el-dialog__header.show-close {
  font-size: 24px;

  @media (max-width: 900px) {
    padding-top: 2px;
  }
}

.ru-locale {
  .el-dialog__header.show-close {
    font-size: 21px;
  }
}

.el-dialog.info {
  max-width: 800px;
  padding: 13px;

  .el-dialog__body {
    max-width: unset;
  }
}

.el-dialog.crafting {
  max-width: 1024px;
  width: 100%;
  overflow: visible;

  .el-dialog__body {
    max-width: 100%;
  }

  @media screen and (max-width: 1400px) {
    max-width: 710px;
  }

  @media screen and (max-width: 900px) {
    overflow: auto;
  }
}

.el-dialog.is-fullscreen {
  width: 100%;
  max-width: 100%;
}

.el-dialog.without-header .el-dialog__header {
  height: 0;
}

.el-dialog {
  border-radius: 12px;
  backdrop-filter: blur(5px);
  background-color: rgba(2, 27, 38, 0.8);
  overflow: hidden;
  box-shadow: 0 0 11px 5px rgba(0, 0, 0, 0.4);
  border: 1px solid #17353f;

  &__header {
    text-align: center;
    margin: 0;
    padding: 1px 0 0 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    font-family: Grenze, sans-serif;
    font-size: 24px;
    font-weight: 800;
    line-height: 1.273em;

    @media screen and (max-width: 991px) {
      padding: 4px 0px 0px 0px;
      font-size: 22px;
    }
  }

  .el-dialog__title {
    font-size: 32px;

    @media screen and (max-width: 767px) {
      font-size: 30px;
    }
  }

  &__body {
    max-width: 415px;
    margin: auto;
    padding-bottom: 30px;
    padding-top: 30px;
    line-height: 1.5;
  }

  &__close,
  .el-icon svg {
    height: 30px;
    width: 30px;
  }
}

.popup-canceled {
  border: 2px solid var(--canceled-color);
  .el-dialog {
    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.popup-confirm {
  border: 2px solid var(--confirm-color);
}

.el-button {
  display: flex;
  padding: 0 38px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 86px;
  background: var(--main-blue);
  text-align: center;
  font-size: 24px;
  color: var(--main-text-color);
  border: 1px solid transparent;
  height: 47px;

  &:active,
  &:focus,
  &:hover {
    background: transparent;
    border: 1px solid #939393;
  }
}

.table .el-table {
  --el-table-border-color: none;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.55);
  &:not(.line-rounded-icon, .line-square-icon) {
    font-family: Grenze, sans-serif;
  }
  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
}

.table .el-table tr {
  background-color: transparent;
}

.table .el-table tr:nth-child(2n) {
  background-color: rgba(0, 0, 0, 0.3);
}

.table .el-table th.el-table__cell {
  background: var(--dark-2);
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
  margin-bottom: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: transparent;
  color: #fff;
  font-family: Grenze, sans-serif;
  font-size: 24px;
  font-weight: 400;

  @media screen and (max-width: 991px) {
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
  }

  @media screen and (max-width: 479px) {
    font-size: 12px;
  }
}

.el-table .el-table__cell {
  @media screen and (max-width: 991px) {
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
  }

  @media screen and (max-width: 479px) {
    font-size: 12px;
  }
}

.el-table .cell {
  word-break: normal;
}

.el-table th.el-table__cell:first-child {
  border-radius: 16px 0 0 0;
}

.el-table th.el-table__cell:last-child {
  border-radius: 0 16px 0 0;
}

.el-icon.el-dialog__close svg {
  display: none;
}

.el-dialog__headerbtn {
  height: 20px;
  width: 20px;
  top: 23px;
  right: 20px;
  z-index: 999;
}

.el-icon.el-dialog__close {
  background-image: url('~/public/img/icons/bone_close.svg');
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;

  &:hover {
    opacity: 0.6;
  }
}

.btns {
  display: flex;
  justify-content: center;
}

.el-button + .el-button {
  margin-left: 0 !important;
}

.el-checkbox__label {
  color: var(--second-text-color);
  text-align: left;
  font-family: Grenze, sans-serif;
  white-space: initial;
  line-height: 1.333em;
  font-size: 16px !important;

  @media screen and (max-width: 767px) {
    font-size: 14px !important;
  }
}

.el-checkbox {
  display: flex;
  align-items: flex-start;
  height: auto !important;
}

.el-checkbox__input {
  margin-top: 4px;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: var(--main-text-color);
}
</style>

<style lang="scss">
.modalCls {
  text-align: center;
}
</style>
